const initialState = {
  message: "",
  videoLink: "",
};

const videoReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_MESSAGE":
      return {
        ...state,
        message: action.payload,
      };
    case "SET_VIDEO_LINK":
      return {
        ...state,
        videoLink: action.payload,
      };
    case "CLEAR_STATE":
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default videoReducer;
