import { createStore, combineReducers } from "redux";
import videoReducer from "./videoReducer";

const rootReducer = combineReducers({
  video: videoReducer,
});

const store = createStore(
  rootReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__
    ? window.__REDUX_DEVTOOLS_EXTENSION__()
    : (f) => f // Fallback for non-supporting browsers
);

export default store;
