import { Dialog, DialogContent } from "@mui/material";

function ProcessingModal({ open }) {
  return (
    <Dialog open={open}>
      <DialogContent sx={{ textAlign: "center" }}>
        <div>
          <img src="/join.png" alt="" width={300} />
        </div>
        <div style={{ margin: "auto", marginTop: "25px" }}>
          <img src="loading.gif" alt="" width={150} />
        </div>
        <h3>Processing...</h3>
      </DialogContent>
    </Dialog>
  );
}

export default ProcessingModal;
