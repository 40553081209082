import React from "react";
import {
  Container,
  Typography,
  TextField,
  Button,
  styled,
  Card,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";

const Div = styled("div")({
  color: "black",
});

const Contact = () => {
  return (
    <div className="contact">
      <Div sx={{ paddingY: "70px" }}>
        <Container maxWidth="sm">
          <Card sx={{ boxShadow: "3px 3px 5px 0.1px", padding: "20px" }}>
            <img src="/favicon.png" alt="" width={100} />
            <Typography variant="h4" gutterBottom>
              Contact Us
            </Typography>
            <TextField label="Name" fullWidth margin="normal" />
            <TextField label="Email" fullWidth margin="normal" />
            <TextField
              label="Message"
              multiline
              rows={4}
              fullWidth
              margin="normal"
            />
            <Button
              variant="contained"
              color="primary"
              startIcon={<SendIcon />}
            >
              Send
            </Button>
          </Card>
        </Container>
      </Div>
    </div>
  );
};

export default Contact;
