import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from "@mui/material";

function FromlinkModal({ open, handleClose }) {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle sx={{ textAlign: "center", fontWeight: "bold" }}>
        <img src="/join.png" alt="" width={250} />
      </DialogTitle>
      <DialogContent sx={{ width: "400px" }}>
        <TextField
          id="outlined-basic"
          label="https://"
          variant="outlined"
          sx={{ width: "100%", marginTop: "10px" }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" variant="contained">
          Import
        </Button>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default FromlinkModal;
