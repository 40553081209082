import React, { useState } from "react";
import axios from "axios";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from "@mui/material";

function JoinWaitListModal({ open, handleClose }) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  const handleJoin = () => {
    axios
      .post("https://getunboxingai.com/api/join", { name: name, email: email })
      .then((res) => {
        if(res.status === 200){
          handleClose()
        }
      });
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle sx={{ textAlign: "center", fontWeight: "bold" }}>
        <img src="/join.png" alt="" width={250} />
      </DialogTitle>
      <DialogContent>
        <TextField
          id="name"
          label="Your Name"
          variant="outlined"
          onChange={(e) => setName(e.target.value)}
          sx={{ width: "100%", marginTop: "10px" }}
        />
        <TextField
          id="email"
          label="Your Email"
          type="email"
          variant="outlined"
          onChange={(e) => setEmail(e.target.value)}
          sx={{ width: "100%", marginTop: "10px" }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleJoin} color="primary" variant="contained">
          Join
        </Button>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default JoinWaitListModal;
