import { useState, useCallback } from "react";
import axios from "axios";
import { useDropzone } from "react-dropzone";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import { Alert, Grid2, Snackbar, styled, Typography } from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import FromCamModal from "../fromcam/FromCamModal";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import FromlinkModal from "../fromlink/FromlinkModal";
import ProcessingModal from "../processing/Process";
import DownloadModal from "../download/downloadModal";

export default function Home() {
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [notification, setNotification] = useState("");
  const [cameraOpen, setCameraOpen] = useState(false);
  const [processOpen, setProcessOpen] = useState(false);
  const [downloadOpen, setDownloadOpen] = useState(false);
  const [productData, setProductData] = useState([]);

  const onDrop = useCallback((acceptedFiles) => {
    uploadVideo(acceptedFiles[0]);
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCameraOpen = () => {
    setCameraOpen(true);
  };

  const handleCameraClose = () => {
    setCameraOpen(false);
  };

  const handleDownClose = () => {
    setDownloadOpen(false);
  };

  const handleCloseNoti = () => {
    setSuccess(false);
    setError(false);
  };

  const uploadVideo = async (file) => {
    const formData = new FormData();
    formData.append("file", file);

    setProcessOpen(true);

    axios
      .post("https://getunboxingai.com/api/upload", formData)
      .then((res) => {
        if (res.error) {
          console.error("Error:", res.error);
          setProcessOpen(false);
          setNotification(res.error);
          setError(true);
        } else {
          setProductData(JSON.parse(res.data.productdata))
          setProcessOpen(false);
          setDownloadOpen(true);
          setNotification(
            "Video processed successfully! Click the link to download."
          );
          setSuccess(true);
        }
      })
      .catch((error) => {
        setProcessOpen(false);
        setNotification(error);
        setError(true);
      });
  };

  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];
    uploadVideo(selectedFile);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: "video/*", // Accept only images
    multiple: false, // Allow multiple file uploads
  });

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  return (
    <Card
      sx={{
        paddingBottom: "50px",
        backgroundColor: "#e5e5e5",
        color: "black",
      }}
    >
      <CardContent>
        <Box sx={{ flexGrow: 1 }}>
          <div className="landing">
            <img src="/join.png" alt="" width="50%" />
            {/* <hr /> */}
            <Grid2
              container
              spacing={12}
              columns={16}
              justifyContent="center"
              sx={{ marginTop: "80px" }}
            >
              <Grid2 size={{ sm: 16, md: 7, xl: 7, lg: 7 }}>
                <Typography
                  style={{
                    textAlign: "left",
                    fontWeight: "bold",
                    fontSize: "30px",
                  }}
                >
                  Revolutionize Your Influencer Tax Compliance
                </Typography>
                <Typography
                  style={{
                    textAlign: "left",
                    marginTop: "20px",
                    fontSize: "20px",
                  }}
                >
                  <p>
                    Say goodbye to the headache of manually valuing influencer
                    pr gifts.
                  </p>
                  <p>
                    Unboxing Tax AI harnesses the power of artificial
                    intelligence to automatically analyze pr unboxing videos,
                    identify products, and accurately assess their market value.
                  </p>
                  <p>
                    Our cutting-edge tool saves accountants and influencers
                    hours of tedious work while ensuring precise tax reporting.
                    With real-time market data and comprehensive reports at your
                    fingertips, you can focus on growing your business or
                    serving more clients.
                  </p>
                  <p>
                    Whether you're an influencer navigating the complex world of
                    taxable gifts or an accounting firm looking to streamline
                    your services, Unboxing Tax AI is your key to effortless
                    compliance and peace of mind. Join the future of influencer
                    taxation – try the demo now.
                  </p>
                </Typography>
              </Grid2>
              <Grid2 size={{ sm: 16, md: 8, xl: 7, lg: 8 }}>
                <Card sx={{ width: "100%", margin: "auto" }}>
                  <CardContent>
                    <div style={{ padding: "20px" }}>
                      <Typography
                        variant="h5"
                        sx={{
                          color: "black",
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        Upload Video - Try it Now
                      </Typography>
                      <Box
                        {...getRootProps()}
                        sx={{
                          border: "2px dashed #9e9e9e",
                          borderRadius: "8px",
                          padding: "20px",
                          textAlign: "center",
                          marginTop: "20px",
                          cursor: "pointer",
                          backgroundColor: isDragActive ? "#e0e0e0" : "#fafafa",
                          "&:hover": {
                            backgroundColor: "#f0f0f0",
                          },
                        }}
                      >
                        <input {...getInputProps()} disabled />
                        <UploadFileIcon
                          sx={{ fontSize: 50, color: "#9e9e9e" }}
                        />
                        {isDragActive ? (
                          <Typography variant="h6" sx={{ color: "#757575" }}>
                            Drop the files here ...
                          </Typography>
                        ) : (
                          <Typography variant="h6" sx={{ color: "#757575" }}>
                            Drag and drop a file here,
                            <br /> or click to select files
                          </Typography>
                        )}
                      </Box>
                      <Button
                        sx={{ marginTop: "30px", width: "100%" }}
                        variant="contained"
                        startIcon={<DriveFolderUploadIcon />}
                        component="label"
                        role={undefined}
                        tabIndex={-1}
                      >
                        From Device
                        <VisuallyHiddenInput
                          type="file"
                          onChange={handleFileChange}
                          multiple
                        />
                      </Button>
                      <Button
                        sx={{ marginTop: "5px", width: "100%" }}
                        variant="outlined"
                        startIcon={<InsertLinkIcon />}
                        onClick={handleClickOpen}
                        disabled
                      >
                        From Link
                      </Button>
                      <FromlinkModal open={open} handleClose={handleClose} />
                      <Button
                        sx={{
                          marginTop: "5px",
                          width: "100%",
                        }}
                        variant="outlined"
                        onClick={handleCameraOpen}
                        startIcon={<CameraAltIcon />}
                        disabled
                      >
                        From Camera
                      </Button>
                      <FromCamModal
                        open={cameraOpen}
                        handleClose={handleCameraClose}
                      />
                    </div>
                  </CardContent>
                </Card>
              </Grid2>
            </Grid2>
          </div>
        </Box>
      </CardContent>
      <ProcessingModal open={processOpen} />
      <DownloadModal
        open={downloadOpen}
        handleClose={handleDownClose}
        data={productData}
      />
      <Snackbar
        open={success}
        autoHideDuration={6000}
        onClose={handleCloseNoti}
      >
        <Alert
          onClose={handleCloseNoti}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {notification}
        </Alert>
      </Snackbar>

      <Snackbar open={error} autoHideDuration={6000} onClose={handleCloseNoti}>
        <Alert
          onClose={handleClose}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {notification}
        </Alert>
      </Snackbar>
    </Card>
  );
}
