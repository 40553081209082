import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Paper,
} from "@mui/material";
import { DataGrid } from '@mui/x-data-grid';

const paginationModel = { page: 0, pageSize: 5 };

const columns = [
  { field: 'product_name', headerName: 'Product Name', width: 300 },
  { field: 'product_price', headerName: 'Price', width: 100 }
];

function DownloadModal({ open, handleClose, data }) {
  const [products, setProducts] = useState([]);

  useEffect(() => {    
    // Check if data is an array and not empty
    if (Array.isArray(data) && data.length > 0) {
      const cleanedProducts = data.map((item, index) => {
        // Clean up the product name and price
        const productName = item.product_name
          .replace(/^\d+\.\s*/, '') // Remove leading numbers and dot (e.g., "1. ")
          .trim();
        
        const productPrice = item.product_price.trim();

        return {
          id: index + 1, // Ensure unique ID
          product_name: productName,
          product_price: productPrice
        };
      }).filter(product => product.product_name && product.product_price); // Filter out invalid entries

      setProducts(cleanedProducts);
    } else {
      console.warn("Data is not an array or is empty:", data);
      setProducts([]); // Reset products if invalid data
    }
  }, [data]);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogContent>
        <Paper sx={{ height: 700, width: '100%' }}>
          <DataGrid
            rows={products}
            columns={columns}
            getRowId={(row) => row.id} // Ensure this returns the unique id
            initialState={{ pagination: { paginationModel } }}
            pageSizeOptions={[5, 10, 25, 50]}
            sx={{ border: 0 }}
          />
        </Paper>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DownloadModal;