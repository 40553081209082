import React from "react";
import { AppBar, Toolbar, IconButton, Typography, styled } from "@mui/material";
import XIcon from "@mui/icons-material/X";
import TikTokIcon from "@mui/icons-material/Whatshot"; // TikTok icon doesn't exist in MUI, using a placeholder
import LinkedInIcon from "@mui/icons-material/LinkedIn";

const FooterContainer = styled(AppBar)(({ theme }) => ({
  top: "auto",
  bottom: 0,
  backgroundColor: theme.palette.primary.main,
  padding: theme.spacing(2),
  display: "flex",
  justifyContent: "center",
}));

const SocialMediaButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.common.white,
  "&:hover": {
    color: theme.palette.secondary.main,
  },
}));

function Footer() {
  const handleTwitterClick = () => {
    window.open("https://x.com/UnboxingTaxAI", "_blank");
  };

  const handleTikTokClick = () => {
    window.open("https://www.tiktok.com/@unboxingtaxai", "_blank");
  };

  const handleLinkedInClick = () => {
    window.open("https://www.linkedin.com/company/unboxing-tax-ai/", "_blank");
  };

  return (
    <FooterContainer position="static">
      <Toolbar>
        <Typography variant="h6" style={{ flexGrow: 1, textAlign: "center" }}>
          Unboxing Tax AI
        </Typography>
        <SocialMediaButton onClick={handleTwitterClick}>
          <XIcon />
        </SocialMediaButton>
        <SocialMediaButton onClick={handleTikTokClick}>
          <TikTokIcon />
        </SocialMediaButton>
        <SocialMediaButton onClick={handleLinkedInClick}>
          <LinkedInIcon />
        </SocialMediaButton>
      </Toolbar>
    </FooterContainer>
  );
}

export default Footer;
